/* Custome Font */
@font-face {
  font-family: intro-rust;
  src: url('assets/font/Intro\ Rust\ G\ Base\ 2\ Line.otf');
  font-weight: 400;
}

@font-face {
  font-family: mogra;
  src: url('assets/font/Mogra-Regular.ttf');
}

@font-face {
  font-family: poppins;
  src: url('assets/font/Poppins-Regular.ttf');
}

@font-face {
  font-family: bangers;
  src: url('assets/font/Bangers-Regular.ttf');
}

@font-face {
  font-family: plus-jakarta;
  src: url('assets/font/PlusJakartaSans-Regular.ttf');
}

@font-face {
  font-family: prompt;
  src: url('assets/font/Prompt-Regular.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-rush {
  font-family: intro-rust;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-hero {
  font-family: mogra;
  font-weight: 400;
}

.text-nav {
  font-family: 'Poppins';
}

.booking{
  font-family: 'plus-jakarta';
}

.booking-style {
  width: 199px;
  height: 73px;
  border-radius: 10px;
}

.text-poppins {
  font-family: 'Poppins';
}

.text-poppins2 {
  font-family: 'Poppins';
}

.text-bangers {
  font-family: bangers;
}

.text-jakarta-sans {
  font-family: 'plus-jakarta';
}

.footer-title{
  font-family: intro-rust;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
}

.active{
  color: white;
}

.text-prompt{
  font-family: prompt;
}

/* Define the scrollbar width and color */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width as desired */
}

/* Define the scrollbar track color */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Change the background color as desired */
}

/* Define the scrollbar thumb color */
::-webkit-scrollbar-thumb {
  background: #888; /* Change the thumb color as desired */
  border-radius: 3px; /* Adjust the border-radius for rounded corners */
}

.custom-loader {
  width: 100px;
  height: 100px;
  display: grid;
  border:8px solid #0000;
  border-radius: 50%;
  border-color:#E4E4ED #0000;
  animation: s6 2s infinite linear;
}
.custom-loader::before,
.custom-loader::after {    
  content:"";
  grid-area: 1/1;
  margin:4px;
  border:inherit;
  border-radius: 50%;
}
.custom-loader::before {
  border-color:#766DF4 #0000;
  animation:inherit; 
  animation-duration: 1s;
  animation-direction: reverse;
}
.custom-loader::after {
  margin:16px;
}

@keyframes s6 { 
  100%{transform: rotate(1turn)}
}


@media (min-width: 640px) {
  .card-package {
    min-height: 615px; /* Atur tinggi maksimum card sesuai kebutuhan */
  }
  
  .card-item{
    min-height: 330px;
  }

  .footer-title{
    font-family: intro-rust;
    font-size: 45px;
    font-weight: 400;
    line-height: 60px;
  }

  /* Define a custom CSS class for the selected state */
  .custom-border {
    border: 2px solid #00aabb; /* Border color when selected */
    transition: border-color 0.3s ease-in-out; /* Add a transition for animation */
    border-top-left-radius: 40px; /* Adjust the radius value as needed */
    border-top-right-radius: 40px; /* Adjust the radius value as needed */
  } 
}

@media (min-width: 1280px) {
  .card-package {
    min-height: 570px; /* Atur tinggi maksimum card sesuai kebutuhan */
  }
  
  .card-item{
    min-height: 330px;
  }

  .footer-title{
    font-family: intro-rust;
    font-size: 45px;
    font-weight: 400;
    line-height: 60px;
  }

  /* Define a custom CSS class for the selected state */
  .custom-border {
    border: 2px solid #00aabb; /* Border color when selected */
    transition: border-color 0.3s ease-in-out; /* Add a transition for animation */
    border-top-left-radius: 40px; /* Adjust the radius value as needed */
    border-top-right-radius: 40px; /* Adjust the radius value as needed */
  } 
}

@media (min-width: 1536px) {
  .card-package {
    min-height: 570px; /* Atur tinggi maksimum card sesuai kebutuhan */
  }
  
  .card-item{
    min-height: 330px;
  }

  .footer-title{
    font-family: intro-rust;
    font-size: 45px;
    font-weight: 400;
    line-height: 60px;
  }

  /* Define a custom CSS class for the selected state */
  .custom-border {
    border: 2px solid #00aabb; /* Border color when selected */
    transition: border-color 0.3s ease-in-out; /* Add a transition for animation */
    border-top-left-radius: 40px; /* Adjust the radius value as needed */
    border-top-right-radius: 40px; /* Adjust the radius value as needed */
  } 
}

@media (min-width: 1920px) {
  .card-package {
    min-height: 650px; /* Atur tinggi maksimum card sesuai kebutuhan */
  }
  
  .card-item{
    min-height: 200px;
  }

  .footer-title{
    font-family: intro-rust;
    font-size: 67px;
    font-weight: 400;
    line-height: 60px;
  }

  /* Define a custom CSS class for the selected state */
  .custom-border {
    border: 2px solid #00aabb; /* Border color when selected */
    transition: border-color 0.3s ease-in-out; /* Add a transition for animation */
    border-top-left-radius: 40px; /* Adjust the radius value as needed */
    border-top-right-radius: 40px; /* Adjust the radius value as needed */
  } 
}

.hidden-whatsapp {
  display: none;
}

/* @media (min-width: 1240px) {
.screen-wii{
  width: 100%;
}

} */

/* Style for the scrollbar track (the background of the scrollbar) */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  border-radius: 5px; /* Make the scrollbar rounded */
}

/* Style for the scrollbar thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  border-radius: 5px; /* Make the thumb rounded */
}

.image-slider-container {
  position: relative;
}

.image-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-circle {
  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-circle.active {
  background-color: #007bff; /* Active circle color */
}
